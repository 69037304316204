import { Result } from "@/assets/script/model/main.model"
import httpUtil from "@/assets/script/utils/http.util"

export enum DefaultPushState {
  PASS = "PASS",
  DISABLE = "DISABLE"
}

interface User {
  head: string
  username: string
}

export interface EquipmentVo {
  //修改默认推送时发给我
  id: string
  //显示睡眠报告时传参
  equipmentId: string
  //设备名称
  equipmentName: string
  //关注时间
  attentionDate: string
  //左侧用户
  leftUser?: User
  //右侧用户
  rightUser?: User
  //是否默认推送
  defaultPushState: DefaultPushState
}

export class ObserverService {
  static verify(state: string): Promise<Result<boolean>> {
    return httpUtil.post("/user/verifyState", { state }, "form")
  }

  static wechatLogin(code: string, state: string) {
    return httpUtil.post("/user/wechatLogin", { code, state }, "form")
  }

  static listEquipmentByWechatState(
    state: string
  ): Promise<Result<EquipmentVo[]>> {
    return httpUtil.get("/observer/listEquipmentByState", { state })
  }

  static updateDefaultPush(
    id: string,
    state: string
  ): Promise<Result<boolean>> {
    return httpUtil.post("/observer/updateDefaultPush", { id, state }, "form")
  }

  static removeObserver(id: string, state: string): Promise<Result<boolean>> {
    return httpUtil.post("/observer/removeObserver", { id, state }, "form")
  }
}
