
import {
  DefaultPushState,
  EquipmentVo,
  ObserverService
} from "@/assets/script/service/observer.service"
import { SUCCESS } from "@/assets/script/constant/main.constant"
import { ref, watch } from "vue"
import { useRoute } from "vue-router"
import qs from "qs"
import {
  HYBRID_HOST,
  OFFICIAL_APP_ID
} from "@/assets/script/constant/config.constant"
import { generateUUID } from "@/assets/script/utils/heighten.util"
import { Toast, Notify, Dialog } from "vant"

const OFFICIAL_STATE = "OFFICIAL_STATE"

export default {
  setup() {
    const list = ref([] as EquipmentVo[])
    const defaultPush = ref(undefined as undefined | string)
    const stateRef = ref(undefined as undefined | string)
    const init = ref(false)
    const href = window.location.href

    const authorize = () => {
      location.replace(
        `https://open.weixin.qq.com/connect/oauth2/authorize?${qs.stringify({
          appid: OFFICIAL_APP_ID,
          ["response_type"]: "code",
          // scope: "snsapi_base",
          scope: "snsapi_userinfo",
          state: generateUUID(),
          ["redirect_uri"]: encodeURI(`${HYBRID_HOST}/#/following`)
        })}`
      )
    }
    const listEquipmentByWechatState = async (state: string) => {
      const result = await ObserverService.listEquipmentByWechatState(state)
      if (result.status === SUCCESS) {
        list.value = result.data!
        stateRef.value = state
        defaultPush.value =
          list.value.find((it) => it.defaultPushState === DefaultPushState.PASS)
            ?.id ?? ""
        init.value = true
      }
    }

    const ready = async () => {
      if (!href.includes(`/#/`)) {
        const queryString = href
          .replace(HYBRID_HOST, "")
          .replace("#/following", "")
        location.replace(`${HYBRID_HOST}/#/following${queryString}`)
        return
      }
      const { query } = useRoute()
      const { code, state } = query as { code: string; state: string }
      //有值，证明微信方回调，不经过下面逻辑
      if (code && state) {
        const toast = Toast.loading({
          message: "加载中...",
          forbidClick: true,
          loadingType: "spinner"
        })
        const result = await ObserverService.wechatLogin(code, state)
        if (result.status === SUCCESS) {
          localStorage.setItem(OFFICIAL_STATE, state)
          await listEquipmentByWechatState(state)
        } else {
          authorize()
        }
        toast.clear()
      } else {
        const storageState = localStorage.getItem(OFFICIAL_STATE)
        //先检验本地是否有状态，如果有
        if (storageState) {
          const toast = Toast.loading({
            message: "加载中...",
            forbidClick: true,
            loadingType: "spinner"
          })
          //校验状态
          const result = await ObserverService.verify(storageState)
          if (result.status === SUCCESS) {
            await listEquipmentByWechatState(storageState)
          } else {
            authorize()
          }
          toast.clear()
        } else {
          authorize()
        }
      }
    }
    ready()

    watch(
      () => defaultPush.value,
      (n, o) => {
        if (o === void 0) return
        ObserverService.updateDefaultPush(n!, stateRef.value!)
      }
    )

    const remove = async (index: number) => {
      const item = list.value[index]
      Dialog.confirm({
        title: "提示",
        message: `确认对设备${item.equipmentName}取消关注`,
        confirmButtonColor: "#5cabd2"
      })
        .then(async () => {
          const result = await ObserverService.removeObserver(
            item.id,
            stateRef.value!
          )
          if (result.status === SUCCESS) {
            list.value.splice(index, 1)
          } else {
            Notify({
              message: result.message,
              background: "#5cabd2"
            })
          }
        })
        .catch(() => {
          // on cancel
        })
    }
    return {
      init,
      list,
      defaultPush,
      remove,
      errorImage: require("../../assets/images/share.png")
    }
  }
}
